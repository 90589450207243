// cas d'erreur
.error-orientation {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  text-align: center;
}
.landscape-mode {
  color: white;
  font-size: 45px;
  font-family: "Circular Std Book";
}

/*Page Principale*/
.index-opinion-page {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  background-color: #fbc82e;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}
.one-stars {
  background-color: #e23926;
}
.two-stars {
  background-color: #e95c27;
}
.three-stars {
  background-color: #f17d25;
}
.four-stars {
  background-color: #fa9d21;
}
.five-stars {
  background-color: #fbc82e;
}

//page de don//

/*Page de base*/
.principal-page {
  position: relative;
  z-index: 2;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow: hidden;
}
.principal-page-after-slide-bottom {
  top: -100vh;
}

.title-page {
  margin: 0;
  margin-top: 2.5vh;
  font-size: 4rem;
  font-family: "Circular Std Black";
}

.body-principal-page {
  position: relative;
  height: 68vh;
  display: flex;
  flex-direction: column;
  padding-top: 7vh;
  align-items: center;
  text-align: center;
  width: 80vw;
  .principal-year {
    font-size: 25rem;
    text-align: center;
    font-family: "Circular Std Black";
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 8;
  }

  .text-principal-page {
    font-size: 1.7rem;
    width: 50%;
    text-align: center;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    i,
    strong {
      font-size: 1.8rem;
      position: fixed;
    }
  }
}

.invisible {
  opacity: 0;
}

.arrow-down-principal-page {
  padding-top: 5vh;
  height: 5vh;
  cursor: pointer;
}

.number-comments {
  position: absolute;
  font-size: 30px;
  bottom: 4vh;
  left: 3.5rem;
  color: white;
}

@media screen and (max-width: 1440px) {
  .body-principal-page {
    .text-principal-page {
      width: 65%;
    }
  }
}
@media screen and (max-width: 1100px) {
  .body-principal-page {
    padding-top: 5vh;
    justify-content: center;
    .principal-year {
      font-size: 15rem;
    }
    .text-principal-page {
      font-size: 1.1rem;
      width: 60%;
      i,
      strong {
        font-size: 1.2rem;
      }
    }
  }
  .arrow-down-principal-page {
    padding-top: 6.7vh;
  }
}
@media screen and (max-width: 1014px) {
  .number-comments {
    position: absolute;
    font-size: 30px;
    bottom: 2rem;
    left: 1rem;
    color: white;
    transition: all ease 0.1s;
  }
}

@media screen and (max-width: 770px) {
  .body-principal-page {
    padding-top: 0vh;

    .principal-year {
      font-size: 12rem;
    }
    .text-principal-page {
      width: 65%;
      font-size: 0.9rem;
      i,
      strong {
        font-size: 1rem;
      }
    }
  }

  .arrow-down-principal-page {
    //display: none;
  }
}

@media screen and (max-width: 550px) {
  .body-principal-page {
    .principal-year {
      font-size: 9rem;
    }
    .text-principal-page {
      width: 90%;
      font-size: 0.9rem;
      i,
      strong {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .body-principal-page {
    .principal-year {
      font-size: 7.8rem;
    }
    .text-principal-page {
      width: 95%;
      font-size: 0.9rem;
      i,
      strong {
        font-size: 1rem;
      }
    }
  }
}

//avis
.show-more {
  text-decoration: underline;
}

.all-opinions-page {
  top: 0vh;
  position: relative;
  z-index: 1;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  .title-all-opinion-page {
    top: 2.5vh;
    margin: 0;
    font-size: 4rem;
    font-family: "Circular Std Black";
    position: fixed;
    text-decoration: none;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }

  &:focus {
    outline: none;
  }
}

.div-list-opinions {
  height: 100vh;
  width: 80%;
  display: flex;
  align-items: center;

  .list-opinions {
    list-style-type: none;
    margin: 0;
    padding: 0;
    // z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    li {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 53vh;
      .opinion-message {
        font-size: 2.2rem;
        padding-bottom: 1rem;
        width: 100%;
        justify-content: flex-start;
        font-family: "Circular Std Book";
        p {
          width: 100%;
          overflow-wrap: break-word;
          margin: 0;
          .title-opinion {
            font-weight: 700;
          }
        }
      }
      .opinion-information {
        display: flex;
        font-size: 1.6rem;
        margin-top: 30px;
        // width: 40%;
        // justify-content: end;
        .opinon-information-elem {
          padding-right: 1rem;
          svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    .prev-opinion {
      filter: blur(5px);
      justify-content: center;
    }
    .current-opinion {
      justify-content: center;
    }
    .next-opinion {
      filter: blur(5px);
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1820px) {
  .div-list-opinions {
    .list-opinions {
      li {
        .opinion-message {
          font-size: 2rem;
          padding-bottom: 0.5rem;
        }
        .opinion-information {
          font-size: 1.5rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .div-list-opinions {
    .list-opinions {
      li {
        height: 60vh;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .div-list-opinions {
    width: 90%;

    .list-opinions {
      li {
        height: 80vh;
      }
    }
  }
}
@media screen and (max-width: 783px) {
  .div-list-opinions {
    .list-opinions {
      li {
        .opinion-message {
          font-size: 1.81rem;
          padding-bottom: 0.5rem;
        }
        .opinion-information {
          font-size: 1.4rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .opinon-information-elem {
            padding-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .div-list-opinions {
    .list-opinions {
      li {
        .opinion-message {
          font-size: 1.5rem;
          padding-bottom: 0.5rem;
        }
        .opinion-information {
          font-size: 1.2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .opinon-information-elem {
            padding-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
