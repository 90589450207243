$slideToBottom: 0.5s ease-in-out both;
$slideToTop: 0.5s ease-out both;
$redirectToCalendar: 0.5s ease-in-out both;
$arriveOnPage: 0.5s ease-in;
$initialSizeTitle: 20rem;
$sizeTitleAfterSlide: 4rem;
$initialPosition: 20vh;
$positionAfterSlide: 2.5vh;
$initialPositionAllOpinion: 0vh;
$positionAfterSlideAllOpinion: -100vh;
$indexInitial: 1;
$indexFinal: 3;
$opacityMax: 1;
$opacityMin: 0;

@mixin changeSizeYear($size, $position) {
  font-size: $size;
  top: $position;
  transform: translate(0, 0);
  left: 0;
}

@mixin changeOpacity($opacity) {
  opacity: $opacity;
}

@mixin changeOpacityAndDisplay($opacity, $display) {
  @include changeOpacity($opacity);
  display: $display;
}

@mixin changePosition($transform, $index) {
  top: $transform;
  z-index: $index;
}

///////Classe///////

.year-animation-down {
  animation: change-place-year $slideToBottom;
  -webkit-animation: change-place-year $slideToBottom;
}

.elem-animation-down {
  animation: slide-elem $slideToBottom;
  -webkit-animation: slide-elem $slideToBottom;
}

.slide-bottom-opinion {
  -webkit-animation: slide-to-bottom-opinion $slideToBottom;
  animation: slide-to-bottom-opinion $slideToBottom;
}

.redirect-to-calendar {
  z-index: 3;
  top: -100vh;
  -webkit-animation: redirect-calendar $redirectToCalendar;
  animation: redirect-calendar $redirectToCalendar;
}

.page-loading {
  -webkit-animation: principal-page-animation $arriveOnPage;
  animation: principal-page-animation $arriveOnPage;
}

///// / First Animation  /////////////
@-webkit-keyframes principal-page-animation {
  0% {
    @include changeOpacity($opacityMin);
  }
  100% {
    @include changeOpacity($opacityMax);
  }
}
@keyframes principal-page-animation {
  0% {
    @include changeOpacity($opacityMin);
  }
  100% {
    @include changeOpacity($opacityMax);
  }
}

////////Animation to bottom////////
@-webkit-keyframes change-place-year {
  0% {
    //@include changeSizeYear($initialSizeTitle, $initialPosition);
  }
  100% {
    @include changeSizeYear($sizeTitleAfterSlide, $positionAfterSlide);
  }
}
@keyframes change-place-year {
  0% {
    //@include changeSizeYear($initialSizeTitle, $initialPosition);
  }
  100% {
    @include changeSizeYear($sizeTitleAfterSlide, $positionAfterSlide);
  }
}

@-webkit-keyframes slide-elem {
  0% {
    @include changeOpacityAndDisplay($opacityMax, block);
  }
  100% {
    @include changeOpacityAndDisplay($opacityMin, none);
  }
}
@keyframes slide-elem {
  0% {
    @include changeOpacityAndDisplay($opacityMax, block);
  }
  100% {
    @include changeOpacityAndDisplay($opacityMin, none);
  }
}

@-webkit-keyframes slide-to-bottom-opinion {
  0% {
    @include changePosition($initialPositionAllOpinion, $indexInitial);
  }
  100% {
    @include changePosition($positionAfterSlideAllOpinion, $indexFinal);
  }
}
@keyframes slide-to-bottom-opinion {
  0% {
    @include changePosition($initialPositionAllOpinion, $indexInitial);
  }
  100% {
    @include changePosition($positionAfterSlideAllOpinion, $indexFinal);
  }
}

////////Animation redirect to Calendar////////

@-webkit-keyframes redirect-calendar {
  0% {
    @include changeOpacity($opacityMax);
  }
  100% {
    @include changeOpacity($opacityMin);
  }
}
@keyframes redirect-calendar {
  0% {
    @include changeOpacity($opacityMax);
  }
  100% {
    @include changeOpacity($opacityMin);
  }
}
