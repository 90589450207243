//Header animation
$popUp: cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

@mixin pop-note-class($time) {
  -webkit-animation: star-number $time $popUp;
  animation: star-number $time $popUp;
}

@mixin pop-note($scale, $opacity) {
  -webkit-transform: scale($scale);
  transform: scale($scale);
  opacity: $opacity;
}

.star-number-2 {
  @include pop-note-class(0.3s);
}
.star-number-3 {
  @include pop-note-class(0.6s);
}
.star-number-4 {
  @include pop-note-class(0.9s);
}
.star-number-5 {
  @include pop-note-class(1.2s);
}

@-webkit-keyframes star-number {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes star-number {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

//Donation animation
$classContent: change-color 0.5s ease 0s 1 both;
$baseFill: #d8aa1e;
$hoverFill: #a27406;

@mixin DonationColor($color) {
  fill: $color;
  opacity: 1;
  cursor: pointer;
}

.don {
  svg:hover {
    -webkit-animation: $classContent;
    -moz-animation: $classContent;
    -ms-animation: $classContent;
    animation: $classContent;
  }
}

.share-logo {
  svg:hover {
    -webkit-animation: $classContent;
    -moz-animation: $classContent;
    -ms-animation: $classContent;
    animation: $classContent;
  }
}
@-webkit-keyframes change-color {
  0% {
    @include DonationColor($baseFill);
  }
  100% {
    @include DonationColor($hoverFill);
  }
}
@keyframes change-color {
  0% {
    @include DonationColor($baseFill);
  }
  100% {
    @include DonationColor($hoverFill);
  }
}

// //mettre le header normale
// $animationChangeOpacity: change-opacity 3s ease 0s 1 both;

// @mixin changeBlur($blur) {
//   filter: blur($blur);
// }

// .before:hover {
//   -webkit-animation: $animationChangeOpacity;
//   -moz-animation: $animationChangeOpacity;
//   -ms-animation: $animationChangeOpacity;
//   animation: $animationChangeOpacity;
// }

// @keyframes change-opacity {
//   0% {
//     @include changeBlur(4px);
//   }
//   100% {
//     @include changeBlur(0);
//   }
// }
