.single-opinion-page {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  top: 105vh;
  align-items: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  // padding-left: 50px;

  ::-webkit-scrollbar {
    display: none;
  }
  .content-single-opinion {
    padding-left: 25px;

    .message-single-opinion {
      height: 60vh;
      font-size: 1.7rem;
      width: 85%;
      overflow-wrap: break-word;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 0;
      padding-bottom: 20px;
    }
    .name-single-opinion {
      font-size: 1.3rem;
    }
  }
  .info-comment-single-opinion {
    position: absolute;
    bottom: 4.5vh;
    left: 1rem;

    p {
      margin: 0;
      margin-bottom: 5px;
      font-size: 20px;
    }
    .note-single-opinion {
      svg {
        height: 27px;
        width: 27px;
      }
    }
  }
  .cross-single-opinion {
    fill: #fff;
    position: absolute;
    bottom: 4vh;
    right: 1rem;
    svg {
      width: 35px;
      pointer-events: none;
      stroke: white;
    }
  }
  .for-safari {
    bottom: 80vh;
    right: 1rem;
    svg {
      width: 35px;
      pointer-events: none;
      stroke: white;
    }
  }
}

.single-opinion-one-stars {
  background-color: #e23926be;
}
.single-opinion-two-stars {
  background-color: #e95c27be;
}
.single-opinion-three-stars {
  background-color: #f17d25be;
}
.single-opinion-four-stars {
  background-color: #fa9d21be;
}
.single-opinion-five-stars {
  background-color: #fbc82ebe;
}
