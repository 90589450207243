$arriveToCalendar: 1s ease-in both;
$leaveToCalendar: 0.5s ease-in both;
$initialOpacity: 0;
$finalOpacity: 1;

@mixin changeOpacity($opacity) {
  opacity: $opacity;
}

.calendar-page {
  animation: arrive-to-calendar $arriveToCalendar;
  -webkit-animation: arrive-to-calendar $arriveToCalendar;
}

.change-scroll::-webkit-scrollbar {
  animation: go-homepage-elem $leaveToCalendar;
  -webkit-animation: go-homepage-elem $leaveToCalendar;
}
.change-scroll,
.title-link-leave {
  animation: go-homepage-elem $leaveToCalendar;
  -webkit-animation: go-homepage-elem $leaveToCalendar;
}

.error-content {
  animation: arrive-to-calendar $arriveToCalendar;
  -webkit-animation: arrive-to-calendar $arriveToCalendar;
}
.title-transition {
  animation: arrive-to-calendar $arriveToCalendar;
  -webkit-animation: arrive-to-calendar $arriveToCalendar;
}

.calendar-page-content,
.title-link {
  @extend .calendar-page;
}

@-webkit-keyframes arrive-to-calendar {
  0% {
    @include changeOpacity($initialOpacity);
  }
  100% {
    @include changeOpacity($finalOpacity);
  }
}
@keyframes arrive-to-calendar {
  0% {
    @include changeOpacity($initialOpacity);
  }
  100% {
    @include changeOpacity($finalOpacity);
  }
}

@-webkit-keyframes go-homepage-elem {
  0% {
    @include changeOpacity($finalOpacity);
  }
  100% {
    @include changeOpacity($initialOpacity);
    display: none;
  }
}
@keyframes go-homepage-elem {
  0% {
    @include changeOpacity($finalOpacity);
    //display: block;
  }
  100% {
    @include changeOpacity($initialOpacity);
    display: none;
  }
}
