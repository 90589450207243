$sizeSlideDown: -90vh;
$slide: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
$slide-opacity: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

@mixin keyframe-slide($transform) {
  -webkit-transform: translateY($transform);
  transform: translateY($transform);
}

@mixin keyframe-slide-opinion($opacity) {
  opacity: $opacity;
}

//show popup
.slide-popup-bottom {
  -webkit-animation: slide-popup-bottom $slide;
  animation: slide-popup-bottom $slide;
}

.slide-popup-top {
  -webkit-animation: slide-popup-top $slide;
  animation: slide-popup-top $slide;
}

//to bottom
@-webkit-keyframes slide-popup-bottom {
  0% {
    @include keyframe-slide($sizeSlideDown);
  }
  100% {
    @include keyframe-slide(0);
  }
}
@keyframes slide-popup-bottom {
  0% {
    @include keyframe-slide($sizeSlideDown);
  }
  100% {
    @include keyframe-slide(0);
  }
}

//to top
@-webkit-keyframes slide-popup-top {
  0% {
    @include keyframe-slide(0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown);
  }
}
@keyframes slide-popup-top {
  0% {
    @include keyframe-slide(0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown);
  }
}
