/*  Global fonction css*/
.note-star {
  display: flex;
  .star {
    display: flex;
    align-items: center;
    height: 100%;
    stroke: white;
  }
}

.cell {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 20px;
  display: table;
}

.card {
  background: white;
  border: 1px solid #c3c6cf;
  border-radius: 15px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  height: 200px;
}
