.page-single-opinion {
  display: flex;
  justify-content: flex-end;
}
.btn-div-single-opinion {
  width: 30rem;
  button {
    background-color: transparent;
    color: white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.content-page-single-opinion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 70vh;
}

.one-opinion-article {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  .opinion-message {
    font-size: 2.2rem;
    padding-bottom: 1rem;
    width: 100%;
    justify-content: flex-start;
    font-family: "Circular Std Book";
    overflow-y: scroll;
    max-height: 300px;
    p {
      width: 100%;
      overflow-wrap: break-word;
      margin: 0;
      .title-opinion {
        font-weight: 700;
      }
    }
  }
  .opinion-information {
    display: flex;
    font-size: 1.6rem;
    .opinon-information-elem {
      padding-right: 1rem;
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

@media screen and (max-width: 1820px) {
  .one-opinion-article {
    .opinion-message {
      font-size: 2rem;
      padding-bottom: 0.5rem;
    }
    .opinion-information {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 783px) {
  .one-opinion-article {
    .opinion-message {
      font-size: 1.81rem;
      padding-bottom: 0.5rem;
    }
    .opinion-information {
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .opinon-information-elem {
        padding-bottom: 0.2rem;
      }
    }
  }
  .btn-div-single-opinion {
    width: 20rem;
  }
}

@media screen and (max-width: 500px) {
  .one-opinion-article {
    .opinion-message {
      font-size: 1.5rem;
      padding-bottom: 0.5rem;
    }
    .opinion-information {
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .opinon-information-elem {
        padding-bottom: 0.2rem;
      }
    }
  }
  .btn-div-single-opinion {
    width: 12rem;
    button {
      font-size: 1.3rem;
    }
  }
}
