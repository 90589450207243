@mixin svg-size($size) {
  svg {
    width: $size;
    pointer-events: none;
    stroke: white;
  }
}

.header {
  transition: all ease 0.5s;

  display: flex;
  flex-direction: column;
  //width: 3%;
  position: fixed;
  z-index: 10;
  left: 3.5rem;
  top: 4vh;
  align-items: center;
  .div-header-top {
    margin-top: 1vh;
  }
  .calendar-header {
    margin-top: 0;
  }
  .button-filter-note {
    margin-top: 0.8vh;
  }
  div {
    cursor: pointer;
    .list-filter-note {
      display: flex;
      justify-content: space-between;
      margin: 0;
      position: absolute;
      z-index: 3;
      left: -0.1rem;
      .filter-single-star {
        pointer-events: none;
        //@include svg-size(50px);
        // stroke: white;
      }
      .filter-star {
        svg {
          padding-left: 5px;
          pointer-events: none;
          //stroke: white;
        }
      }
      .star-number-0 {
        svg {
          padding-left: 0;
        }
      }
    }
    .single-filter-note {
      // width: 50px;
    }
  }
}

.behind {
  z-index: 3;
  filter: blur(2px);
  transition: all ease 0.1s;
}

@media screen and (max-width: 1014px) {
  .header {
    // width: 40%;
    // width: 20%;
    bottom: 4vh;
    left: auto;
    top: auto;
    right: 1rem;
    align-items: flex-end;
    // align-items: baseline; // normalement c good
    // height: 52px;
    .div-header-top {
      // margin-top: 0;
      @include svg-size(35px);
    }
    .calendar-header {
      position: relative;
      bottom: -1px; //pas sur
    }

    div {
      .list-filter-note {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        position: relative;
        left: 0;
        flex-direction: row-reverse;

        .filter-single-star {
          pointer-events: none;
          // padding-left: 0.5rem;
          padding-right: -50px;

          @include svg-size(40px);
        }
        .filter-star {
          padding-left: 0%;
          // padding-right: 2px;

          @include svg-size(40px);
        }

        .star-number-0 {
          svg {
            padding-left: 5px;
          }
        }
      }
    }
  }
  .add-header,
  .header-calendar {
    flex-direction: row;
    width: 10%;
    top: 4vh;
    left: 0.5rem;
    align-items: flex-end;
    height: 52px;
    justify-content: space-between;

    .div-header-top {
      margin-top: 0;
      margin-right: 0rem;
    }
  }
}

//DOnate

.don {
  position: fixed;
  right: 3.5rem;
  bottom: 4vh;
  fill: #d8aa1e;
  z-index: 5;

  input[type="submit"] {
    display: none;
  }
  // a {
  //   color: black;
  //   text-decoration: none;
  // }
  svg:hover {
    cursor: pointer;
  }
}
.contact {
  @extend .don;
  color: white;
  font-family: "Circular Std Book";
  font-size: 20px;
  a {
    text-decoration: none;
    color: white;
  }
}

@media screen and (max-width: 1014px) {
  .don {
    display: none;
  }
}
