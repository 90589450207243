body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular Std Book";
  src: url("./assets/font/CircularStd-BookItalic.woff2") format("woff2"),
    url("./assets/font/CircularStd-BookItalic.woff") format("woff"),
    url("./assets/font/CircularStd-BookItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Bold Italic";
  src: url("./assets/font/CircularStd-BoldItalic.woff2") format("woff2"),
    url("./assets/font/CircularStd-BoldItalic.woff") format("woff"),
    url("./assets/font/CircularStd-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Book";
  src: url("./assets/font/CircularStd-Book.woff2") format("woff2"),
    url("./assets/font/CircularStd-Book.woff") format("woff"),
    url("./assets/font/CircularStd-Book.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Medium";
  src: url("./assets/font/CircularStd-Medium.woff2") format("woff2"),
    url("./assets/font/CircularStd-Medium.woff") format("woff"),
    url("./assets/font/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Medium Italic";
  src: url("./assets/font/CircularStd-MediumItalic.woff2") format("woff2"),
    url("./assets/font/CircularStd-MediumItalic.woff") format("woff"),
    url("./assets/font/CircularStd-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Bold";
  src: url("./assets/font/CircularStd-Bold.woff2") format("woff2"),
    url("./assets/font/CircularStd-Bold.woff") format("woff"),
    url("./assets/font/CircularStd-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Black Italic";
  src: url("./assets/font/CircularStd-BlackItalic.woff2") format("woff2"),
    url("./assets/font/CircularStd-BlackItalic.woff") format("woff"),
    url("./assets/font/CircularStd-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Circular Std Black";
  src: url("./assets/font/CircularStd-Black.woff2") format("woff2"),
    url("./assets/font/CircularStd-Black.woff") format("woff"),
    url("./assets/font/CircularStd-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
