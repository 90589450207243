//variable
$slide: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
$sizeSlideUp: -53vh;
$sizeSlideMiddleUp: -60vh;
$sizeSlideLittleUp: -80vh;
$sizeSlideDown: 53vh;
$sizeSlideMiddleDown: 60vh;
$sizeSlideLittleDown: 80vh;
$slideUpFirst: -40vh;
$slideDownLast: 40vh;

@mixin keyframe-slide($transform, $blur) {
  -webkit-transform: translateY($transform);
  transform: translateY($transform);
  filter: blur($blur);
}

@mixin keyframe-slide-first-last($transform, $blur) {
  -webkit-transform: translateY($transform);
  transform: translateY($transform);
  filter: blur($blur);
}

///////Slide Up//////////////////
.slide-top-for-current {
  -webkit-animation: slide-top-for-current $slide;
  animation: slide-top-for-current $slide;
}
.slide-top-for-next {
  -webkit-animation: slide-top-for-next $slide;
  animation: slide-top-for-next $slide;
}
.slide-top-for-prev {
  -webkit-animation: slide-top-for-prev $slide;
  animation: slide-top-for-prev $slide;
}

//middle size

@media screen and (max-width: 1450px) {
  .slide-top-for-current {
    -webkit-animation: slide-top-for-current-middle $slide;
    animation: slide-top-for-current-middle $slide;
  }
  .slide-top-for-next {
    -webkit-animation: slide-top-for-next-middle$slide;
    animation: slide-top-for-next-middle $slide;
  }
  .slide-top-for-prev {
    -webkit-animation: slide-top-for-prev-middle $slide;
    animation: slide-top-for-prev-middle $slide;
  }
}
//Little size

@media screen and (max-width: 783px) {
  .slide-top-for-current {
    -webkit-animation: slide-top-for-current-little $slide;
    animation: slide-top-for-current-little $slide;
  }
  .slide-top-for-next {
    -webkit-animation: slide-top-for-next-little $slide;
    animation: slide-top-for-next-little $slide;
  }
  .slide-top-for-prev {
    -webkit-animation: slide-top-for-prev-little $slide;
    animation: slide-top-for-prev-little $slide;
  }
}

//current
@-webkit-keyframes slide-top-for-current {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 5px);
  }
}
@keyframes slide-top-for-current {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 5px);
  }
}
// middle Size
@-webkit-keyframes slide-top-for-current-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 5px);
  }
}
@keyframes slide-top-for-current-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 5px);
  }
}
// little Size
@-webkit-keyframes slide-top-for-current-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 5px);
  }
}
@keyframes slide-top-for-current-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 5px);
  }
}

//next
@-webkit-keyframes slide-top-for-next {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 0);
  }
}
@keyframes slide-top-for-next {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 0);
  }
}

//Middle size
@-webkit-keyframes slide-top-for-next-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 0);
  }
}
@keyframes slide-top-for-next-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 0);
  }
}
//Little size
@-webkit-keyframes slide-top-for-next-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  60% {
    filter: blur(5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 0);
  }
}
@keyframes slide-top-for-next-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  60% {
    filter: blur(5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 0);
  }
}

//prev
@-webkit-keyframes slide-top-for-prev {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 5px);
  }
}
@keyframes slide-top-for-prev {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideUp, 5px);
  }
}

//middle size
@-webkit-keyframes slide-top-for-prev-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 5px);
  }
}
@keyframes slide-top-for-prev-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleUp, 5px);
  }
}
//little size
@-webkit-keyframes slide-top-for-prev-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 5px);
  }
}
@keyframes slide-top-for-prev-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleUp, 5px);
  }
}

///////End Slide Up//////////////////
///////Slide Down//////////////////
.slide-bottom-for-current {
  -webkit-animation: slide-bottom-for-current $slide;
  animation: slide-bottom-for-current $slide;
}
.slide-bottom-for-prev {
  -webkit-animation: slide-bottom-for-prev $slide;
  animation: slide-bottom-for-prev $slide;
}
.slide-bottom-for-next {
  -webkit-animation: slide-bottom-for-next $slide;
  animation: slide-bottom-for-next $slide;
}

//middle size
@media screen and (max-width: 1450px) {
  .slide-bottom-for-current {
    -webkit-animation: slide-bottom-for-current-middle $slide;
    animation: slide-bottom-for-current-middle $slide;
  }
  .slide-bottom-for-prev {
    -webkit-animation: slide-bottom-for-prev-middle $slide;
    animation: slide-bottom-for-prev-middle $slide;
  }
  .slide-bottom-for-next {
    -webkit-animation: slide-bottom-for-next-middle $slide;
    animation: slide-bottom-for-next-middle $slide;
  }
}
//Little size
@media screen and (max-width: 783px) {
  .slide-bottom-for-current {
    -webkit-animation: slide-bottom-for-current-little $slide;
    animation: slide-bottom-for-current-little $slide;
  }
  .slide-bottom-for-prev {
    -webkit-animation: slide-bottom-for-prev-little $slide;
    animation: slide-bottom-for-prev-little $slide;
  }
  .slide-bottom-for-next {
    -webkit-animation: slide-bottom-for-next-little $slide;
    animation: slide-bottom-for-next-little $slide;
  }
}

//Current
@-webkit-keyframes slide-bottom-for-current {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 5px);
  }
}
@keyframes slide-bottom-for-current {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 5px);
  }
}

//Middle Size
@-webkit-keyframes slide-bottom-for-current-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 5px);
  }
}
@keyframes slide-bottom-for-current-middle {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 5px);
  }
}

//Little Size
@-webkit-keyframes slide-bottom-for-current-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 5px);
  }
}
@keyframes slide-bottom-for-current-little {
  0% {
    @include keyframe-slide(0, 0);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 5px);
  }
}

//Prev
@-webkit-keyframes slide-bottom-for-prev {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 0);
  }
}
@keyframes slide-bottom-for-prev {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 0);
  }
}
//middle-size
@-webkit-keyframes slide-bottom-for-prev-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 0);
  }
}
@keyframes slide-bottom-for-prev-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 0);
  }
}
//little-size
@-webkit-keyframes slide-bottom-for-prev-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 0);
  }
}
@keyframes slide-bottom-for-prev-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 0);
  }
}

//Next
@-webkit-keyframes slide-bottom-for-next {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 5px);
  }
}
@keyframes slide-bottom-for-next {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideDown, 5px);
  }
}

//middle sizeee
@-webkit-keyframes slide-bottom-for-next-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 5px);
  }
}
@keyframes slide-bottom-for-next-middle {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideMiddleDown, 5px);
  }
}
//little sizeee
@-webkit-keyframes slide-bottom-for-next-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 5px);
  }
}
@keyframes slide-bottom-for-next-little {
  0% {
    @include keyframe-slide(0, 5px);
  }
  100% {
    @include keyframe-slide($sizeSlideLittleDown, 5px);
  }
}

///////End Slide Down//////////////////

/////// Slide Down last //////////////////
.slide-top-for-current-last {
  -webkit-animation: slide-top-for-current-last $slide;
  animation: slide-top-for-current-last $slide;
}
.slide-top-for-prev-last {
  -webkit-animation: slide-top-for-prev-last $slide;
  animation: slide-top-for-prev-last $slide;
}
//current
@-webkit-keyframes slide-top-for-current-last {
  0% {
    @include keyframe-slide-first-last(0, 0);
  }
  50% {
    @include keyframe-slide-first-last(-50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 0);
  }
}
@keyframes slide-top-for-current-last {
  0% {
    @include keyframe-slide-first-last(0, 0);
  }
  50% {
    @include keyframe-slide-first-last(-50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 0);
  }
}
//prev
@-webkit-keyframes slide-top-for-prev-last {
  0% {
    @include keyframe-slide-first-last(0, 5px);
  }
  50% {
    @include keyframe-slide-first-last(-50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 5px);
  }
}
@keyframes slide-top-for-prev-last {
  0% {
    @include keyframe-slide-first-last(0, 5px);
  }
  50% {
    @include keyframe-slide-first-last(-50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 5px);
  }
}
///////End Slide Down last //////////////////

///////slide up first//////////////////
.slide-bottom-for-current-first {
  -webkit-animation: slide-top-for-current-first $slide;
  animation: slide-top-for-current-first $slide;
}
.slide-bottom-for-next-first {
  -webkit-animation: slide-top-for-next-first $slide;
  animation: slide-top-for-next-first $slide;
}
//current
@-webkit-keyframes slide-top-for-current-first {
  0% {
    @include keyframe-slide-first-last(0, 0);
  }
  50% {
    @include keyframe-slide-first-last(50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 0);
  }
}
@keyframes slide-top-for-current-first {
  0% {
    @include keyframe-slide-first-last(0, 0);
  }
  50% {
    @include keyframe-slide-first-last(50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 0);
  }
}
//next
@-webkit-keyframes slide-top-for-next-first {
  0% {
    @include keyframe-slide-first-last(0, 5px);
  }
  50% {
    @include keyframe-slide-first-last(50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 5px);
  }
}
@keyframes slide-top-for-next-first {
  0% {
    @include keyframe-slide-first-last(0, 5px);
  }
  50% {
    @include keyframe-slide-first-last(50vh, 5px);
  }
  100% {
    @include keyframe-slide-first-last(0, 5px);
  }
}

/////// end slide up first//////////////////

.animation-scroll-current {
  -webkit-animation: animation-scroll-current $slide;
  animation: animation-scroll-current $slide;
}

@-webkit-keyframes animation-scroll-current {
  0% {
    filter: blur(0);
  }
  100% {
    filter: blur(5px);
  }
}
@keyframes animation-scroll-current {
  0% {
    filter: blur(0);
  }
  100% {
    filter: blur(5px);
  }
}
