.transition-page {
  height: 100vh;
  text-align: center;

  h2 {
    margin: 0;
    font-size: 10rem;
    color: white;
  }
}
.transition-content {
  position: fixed;
  top: 50vh;
  right: 50vw;
  transform: translate(50%, -50%);
  text-align: center;
  font-family: "Circular Std Medium";

  img {
    height: 100px;
    width: 100px;
  }
  .strong-error {
    font-size: 4rem;
    font-weight: 700;
  }
  .error-content {
    font-size: 3rem;
  }
}
