$arriveOnPage: 0.5s ease-in-out;
$changePage: 0.5s ease-out both;
$slide: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
$sizeSlideDown: 90vh;
$opacityMin: 0;
$opacityMax: 1;
$originPosition: -350px;
$finalPosition: 10px;

@mixin keyframe-slide($transform) {
  -webkit-transform: translateY($transform);
  transform: translateY($transform);
}

@mixin changeOpacity($opacity) {
  opacity: $opacity;
}

//charge page

.add-page {
  -webkit-animation: charge-page $arriveOnPage;
  animation: charge-page $arriveOnPage;
}

@-webkit-keyframes charge-page {
  0% {
    @include changeOpacity($opacityMin);
  }
  100% {
    @include changeOpacity($opacityMax);
  }
}
@keyframes charge-page {
  0% {
    @include changeOpacity($opacityMin);
  }
  100% {
    @include changeOpacity($opacityMax);
  }
}

//change page
.slide-send {
  -webkit-animation: slide-send $slide;
  animation: slide-send $slide;
}

.change-page {
  -webkit-animation: change-page $changePage;
  animation: change-page $changePage;
}

@-webkit-keyframes change-page {
  0% {
    @include changeOpacity($opacityMax);
  }
  100% {
    @include changeOpacity($opacityMin);
  }
}
@keyframes change-page {
  0% {
    @include changeOpacity($opacityMax);
  }
  100% {
    @include changeOpacity($opacityMin);
  }
}

//to bottom
@-webkit-keyframes slide-send {
  0% {
    @include keyframe-slide(0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown);
    display: none;
  }
}
@keyframes slide-send {
  0% {
    @include keyframe-slide(0);
  }
  100% {
    @include keyframe-slide($sizeSlideDown);
    display: none;
  }
}

//show component
.show-component-after-send {
  -webkit-animation: show-component-after-send $slide;
  animation: show-component-after-send $slide;
}

//to bottom
@-webkit-keyframes show-component-after-send {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes show-component-after-send {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
//show Error popup
.pop-up-error {
  -webkit-animation: pop-up-error $slide;
  animation: pop-up-error $slide;
}

//to bottom
@-webkit-keyframes pop-up-error {
  0% {
    right: $originPosition;
  }
  100% {
    right: $finalPosition;
  }
}
@keyframes pop-up-error {
  0% {
    right: $originPosition;
  }
  100% {
    right: $finalPosition;
  }
}
