body {
  background-color: #fbc82e;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

::-moz-selection {
  /* Code for Firefox */
  background: #fbc82e;
}

::selection {
  background: #fbc82e;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #fbc82e;
}
::-webkit-scrollbar-thumb {
  background: white;
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */
