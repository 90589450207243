//allPAge
.calendar-page {
  opacity: 0;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;

  .title-link,
  .title-link-leave {
    text-decoration: none;
    color: white;
    margin: 0;
    z-index: 5;
    position: fixed;
    top: 2.5vh;
    left: 50%;
    font-family: "Circular Std Black";
    font-size: 4rem;
    transform: translate(-50%, 0);
    cursor: pointer;
  }

  .div-flou-top {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 20vh;
    background: rgb(251, 200, 46);
    background: linear-gradient(
      0deg,
      rgba(251, 200, 46, 0) 0%,
      rgba(251, 200, 46, 1) 60%
    );
    top: -5vh;
  }

  .div-flou-down {
    z-index: 1;
    position: fixed;
    background-color: rgb(251, 200, 46);
    background: linear-gradient(
      180deg,
      rgba(251, 200, 46, 0) 0%,
      rgba(251, 200, 46, 1) 70%
    );
    height: 25vh;
    width: 100%;
    bottom: 0vh;
    pointer-events: none;
  }

  .calendar-page-content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

//Single YEars

.single-year-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .single-year {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 17.75rem;
    font-family: "Circular Std Black";
    align-items: center;

    .arrow {
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;
      cursor: pointer;
    }

    .invalid-year {
      color: #d8aa1e;
    }
    .valid-year {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 800px) {
  .single-year-page {
    .single-year {
      font-size: 10rem;
    }
  }
}

//All years

.all-years-content {
  list-style-type: none;
  overflow-y: scroll;
  height: 100vh;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20rem;
  position: relative;

  li {
    font-family: "Circular Std Black";
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;
    align-items: center;
    width: 30%;
    font-size: 11.188rem;

    .all-note {
      svg {
        width: 45px;
        height: 45px;
      }
    }
    .note-desabled {
      svg {
        stroke: #d8aa1e;
      }
    }
  }
  .li-invisible {
    display: none;
  }

  .year-futur {
    color: #d8aa1e;
    cursor: default;
  }
  .year {
    .elem-year {
      cursor: pointer;
    }
  }

  .last-year {
    padding-bottom: 20rem;
  }
}

@media screen and (max-width: 1500px) {
  .all-years-content {
    li {
      font-size: 8rem;
    }
  }
}

@media screen and (max-width: 1100px) {
  .all-years-content {
    width: 95%;
    li {
      font-size: 5rem;
    }
  }
}

@media screen and (max-width: 1014px) {
  .all-years-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 13rem;
    li {
      padding-bottom: 3rem;
      font-size: 7.438rem;
    }
    .first-year {
      padding-top: 0;
    }
  }
}
