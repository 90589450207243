$btnColor: #676767;
$borderTopInput: 1px solid rgba(0, 0, 0, 0.25);

.add-page {
  height: 100%;
  width: 100vw;
  color: white;
  position: relative;
  overflow: hidden;
  background-color: #fbc82e;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  .add-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    .title-link {
      margin: 0;
      font-family: "Circular Std Black";
      font-size: 4rem;
      padding-top: 2.5vh;
      text-decoration: none;
      color: white;
      cursor: pointer;
    }
    // .year-add-opinion {
    //   margin: 0;
    //   font-size: 2rem;
    // }
  }
}

.form-add-opinion {
  height: 600px;
  top: 20vh;
  position: fixed;
  z-index: 2;
  background-color: white;
  width: 31.25rem;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  // overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    background: #fbc82e;
  }
  .inputs-form {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Circular Std Medium";
    font-size: 1.125rem;

    .all-note-form {
      display: flex;
      justify-content: center;
      div {
        padding-right: 8px;
      }
    }

    input {
      border: 0;
      border-top: $borderTopInput;
      font-size: 1.5rem;
      font-family: "Circular Std Medium";
      margin-top: 1rem;
      padding-top: 1rem;

      &:focus {
        outline: none;
      }
    }

    textarea {
      @extend input;
      resize: none;
      margin-top: 2rem;
      //      border-bottom: 1px solid black;
      &:focus {
        outline: none;
      }
    }
    ::placeholder {
      color: #bfbfbf;
    }
  }
  .error-input {
    width: 100%;
    pointer-events: none;
    font-family: "Circular Std Medium";

    .erreur-message-form {
      color: red;
      margin: 0;
      margin-bottom: 0.2rem;
      font-size: 0.9rem;
    }

    .inactive {
      color: white;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.btn-div {
  width: 100%;
  border-top: $borderTopInput;
  height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 10px;
}

.btn-add-opinions {
  width: 100%;
  height: 4rem;
  font-size: 1.375rem;
  background-color: $btnColor;
  border-color: $btnColor;
  border-radius: 15px;
  color: white;
  appearance: none;
  cursor: pointer;
  border-style: none;
  &:focus {
    border: none;
    outline: none;
  }
}

.btn-add-opinions-disabled {
  @extend .btn-add-opinions;
  background-color: rgba(56, 56, 56, 0.3);
  color: #676767;
  cursor: default;
}

.gif-loading-form {
  position: fixed;
  top: 50%;
  img {
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 515px) {
  .form-add-opinion {
    height: 80vh;
    width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 30px;
    padding-top: 10px;
    justify-content: space-evenly;
    position: initial;
    // overflow-y: scroll;
    .all-note-form {
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .inputs-form {
      font-size: 1rem;
      width: 90%;
      input {
        font-size: 1rem;
        margin-top: 1rem;
        padding-top: 0.5rem;
      }

      textarea {
        font-size: 1rem;
        margin-top: 1.5rem;
        height: 50vh;
      }
    }
    .error-input {
      .erreur-message-form {
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: 0.8rem;
      }
    }

    // .btn-add-opinions-disabled {
    //   @extend .btn-add-opinions;
    // }
  }
  .btn-div {
    width: 90%;
  }

  .btn-add-opinions {
    width: 100%;
  }
}

.after-send {
  position: fixed;
  top: 21vh;
  height: 60vh;
  opacity: 0;
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  font-family: "Circular Std Medium";
  align-items: center;
  z-index: 1;

  .component-after-send {
    display: flex;
    flex-direction: column;
    height: 40vh;
    padding-top: 10vh;
    .thank-message {
      font-size: 2.5rem;
      color: #dea311;
      text-align: center;
    }
    .share-logo {
      padding-top: 5vh;
      display: flex;
      justify-content: center;

      button {
        &:focus {
          outline: none;
        }
      }
      svg {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 55px;
        width: 55px;
        fill: #dea311;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .confirm-send {
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 90%;
    background: white;
    //border: black solid 1px;
    color: #fbc82e;
    font-size: 1.5rem;
  }
}

.pop-up-error {
  position: absolute;
  width: 330px;
  height: 100px;
  background-color: #e23926;
  top: 2vh;
  right: -350px;
  z-index: 2;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  .cross-popup-error {
    position: absolute;
    top: 10px;
    right: 10px;
    fill: white;
    &:hover {
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-size: 1.1rem;
    font-family: "Circular Std Book";
    pointer-events: none;
  }
  //transform: translate(-50%, -50%);
}

@media screen and (max-width: 500px) {
  .after-send {
    .component-after-send {
      .thank-message {
        font-size: 1.8rem;
      }
    }
    .confirm-send {
      width: 60%;
    }
  }
  // .add-page {
  //   .add-page-content {
  //     .year-add-opinion {
  //       font-size: 1.5rem;
  //       margin-top: -10px;
  //       margin-bottom: 20px;
  //     }
  //   }
  // }
}

@media screen and (max-width: 300px) {
  .add-page {
    height: 100vh;
    background-color: #fbc82e;
    .add-page-content {
      display: none;
    }
    // .year-add-opinion {
    //   font-size: 0.5rem;
    // }
  }
}
